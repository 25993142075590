/* -- set app title --*/
const AppTitle = 'NFT';

/* -- set app mode -- */
// const AppMode = [''];
const AppMode = ['development'];

/* -- set API URLs --*/
const production = 'https://www.dnft-marketplace-server.softtik.com/api';
const staging = 'https://www.dnft-marketplace-server.softtik.com/api';
const development = 'https://www.dnft-marketplace-server.softtik.com/api';


let env = AppMode[0] || 'development', ApiUrl = '', networkId = '', message = '', explorer = '';

switch (AppMode[0]) {
  case 'development':
    networkId = 4;
    message = 'Please switch your network to Rinkeby testnet';
    ApiUrl = development;
    explorer = 'https://rinkeby.etherscan.io'
    break;
  case 'production':
    networkId = 56;
    ApiUrl = production;
    message = 'Please switch your network to BSC Mainnet';
    explorer = 'https://bscscan.com'
    break;
  case 'staging':
    networkId = 4;
    ApiUrl = staging;
    message = 'Please switch your network to Rinkeby testnet';
    explorer = 'https://rinkeby.etherscan.io'
    break;
  default:
    networkId = 4;
    ApiUrl = 'http://192.168.18.41:4001/api';
    message = 'Please switch your network to Rinkeby testnet';
    explorer = 'https://rinkeby.etherscan.io'
}

export { AppTitle, ApiUrl, networkId, message, explorer, env };
