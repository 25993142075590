import moment from 'moment';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Button from '@material-ui/core/Button';
import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import { explorer } from '../../store/config';
import Loader from '../../components/Loader';
import { getUsersData, toggleEditUser, toggleDeleteUser, deleteUser } from '../../store/actions/Auth.js';


import './index.css';
import Slider from './slider';

class UsersDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    props.getUsersData();
  };

  handleDeleteUser = (id) => {
    this.setState({ id });
    this.props.toggleDeleteUser();
  }

  render() {
    let { id } = this.state;
    let { allUsersData, isDeleteModal } = this.props;
    const columns = [
      {
        id: 'name',
        Header: 'Name',
        accessor: allUsersData => allUsersData['name'] ? allUsersData['name'] : '-',
      }, {
        id: 'email',
        Header: 'Email',
        accessor: allUsersData => allUsersData['email'] ? allUsersData["email"] : '-',
      }, {
        id: 'phoneNumber',
        Header: 'Phone Number',
        accessor: allUsersData => allUsersData['phone'] ? allUsersData["phone"] : '-',
      }, {
        id: 'country',
        Header: 'Country',
        accessor: allUsersData => allUsersData['country'] ? allUsersData["country"] : '-',
      }, {
        id: 'createdAt',
        Header: 'Created At',
        accessor: allUsersData => allUsersData['createdAt'] ? moment(allUsersData["createdAt"]).format("lll") : '-',
      }, {
        id: 'publicAddress',
        Header: 'Public Address',
        accessor: allUsersData => allUsersData['publicAddress']
          ? <a href={`${explorer}/address/${allUsersData['publicAddress']}`} target='_blank' style={{ color: '#1c6da8' }}>
            View on Explorer
          </a>
          : '-',
      }, {
        id: 'actions',
        Header: 'Actions',
        accessor: allUsersData => <Fragment>
          <Button variant="outlined" className="mx-1 view-btn" onClick={() => this.props.toggleEditUser(allUsersData)}><i className='fa fa-edit' /></Button>
          <Button variant="outlined" className="mx-1 delete-btn" onClick={() => this.handleDeleteUser(allUsersData['_id'])}><i className='fa fa-trash' /></Button>

        </Fragment>,
        filterable: false,
      }
    ];

    return (
      <div className='content'>
        <div className="main-container">
          <Slider />
          {!allUsersData
            ? <Loader />
            : <Fragment>
              <div className='main-container-head mb-3'>
                <p className="main-container-heading">All Users Data</p>
              </div>
              <ReactTable
                className="table"
                data={allUsersData}
                resolveData={allUsersData => allUsersData.map(row => row)}
                columns={columns}
                minRows={20}
                filterable={true}
              />
            </Fragment>
          }
        </div>

        {/* ---------------DELETE MODAL--------------- */}

        <Modal isOpen={isDeleteModal} toggle={this.props.toggleDeleteUser} className="main-modal delete-modal">
          <ModalHeader toggle={this.props.toggleDeleteUser}>
            <div className="modal-logo">
              <img src={require('../../assets/img/logo.png')} alt="modal-logo" />
            </div>
            <div className="delete-modal-title"><p>Delete User</p></div>
            <div className="delete-modal-line"><hr /></div>
          </ModalHeader>
          <ModalBody className="modal-body">
            <div className="row">
              <div className="col-12">
                <p className="modal-body-title">Are you sure you want to delete this user!</p>
              </div>
              <div className="col-12 modal-body-btn mt-3">
                <Button variant="outlined" className="mx-1 delete-btn" onClick={this.props.toggleDeleteUser}>Cancel</Button>
                <Button variant="outlined" className="mx-1 view-btn" onClick={() => this.props.deleteUser(id)}>Submit</Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

      </div>
    );
  }
}

const mapDispatchToProps = {
  getUsersData,
  toggleEditUser,
  toggleDeleteUser,
  deleteUser,
};

const mapStateToProps = ({ Auth }) => {
  let { allUsersData, isDeleteModal } = Auth;
  return { allUsersData, isDeleteModal }
};
export default connect(mapStateToProps, mapDispatchToProps)(UsersDetails);
