import Dashboard from "./views/Dashboard/index.js";
// import TransactionsData from "./views/TransactionsData/index.js";
// import TokenControls from "./views/TokenControls/index.js";
// import TokenDetails from "./views/TokenDetails/index.js";
import UsersDetails from "./views/UsersDetails/index.js";
// import Minting from "./views/Minting/index.js";
// import Burn from "./views/Burn/index.js";
import Settings from "./views/Settings/index.js";


var routes = [
  {
    layout: "/home",
    path: "/",
    component: Dashboard,
    hidden: true,
  },
  {
    layout: "/home",
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    icon: "tim-icons icon-chart-pie-36",
  },
  // {
  //   layout: "/home",
  //   path: "/transactionsData ",
  //   name: "Transactions Data ",
  //   component: TransactionsData,
  //   icon: "tim-icons icon-paper",
  // },
  // {
  //   layout: "/home",
  //   path: "/tokenControls",
  //   name: "Token Controls",
  //   component: TokenControls,
  //   icon: "tim-icons icon-settings",
  // }, 
  // {
  //   layout: "/home",
  //   path: "/tokenDetails",
  //   name: "Token Details",
  //   component: TokenDetails,
  //   icon: "tim-icons icon-money-coins",
  // },
  {
    layout: "/home",
    path: "/usersDetails",
    name: "Users Details",
    component: UsersDetails,
    icon: "tim-icons icon-badge",
  },
  // {
  //   layout: "/home",
  //   path: "/burn",
  //   name: "Burn",
  //   component: Burn,
  //   icon: "tim-icons icon-scissors",
  // },
  // {
  //   layout: "/home",
  //   path: "/minting",
  //   name: "Minting",
  //   component: Minting,
  //   icon: "tim-icons icon-molecule-40",
  // },
  {
    layout: "/home",
    path: "/settings",
    name: "Admin Settings",
    component: Settings,
    icon: "tim-icons icon-settings-gear-63",
  },
];

export default routes;